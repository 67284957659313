import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export const initGsap = () => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.config({
        autoRefreshEvents: "visibilitychange,DOMContentLoaded,load"
    })

    ScrollTrigger.getAll().forEach(t => t.kill());

    // Scroll Scrub Animations
    document.querySelectorAll(`[data-scroll]`).forEach(element => {

        switch ((element as HTMLElement).dataset.scrollType) {
            case "expand-on-scroll":
                if (element) {
                    const section: HTMLDivElement | null = element as HTMLDivElement;
                    const expanding_element: HTMLDivElement | null = element.querySelector(`[data-scroll-element]`);

                    const currentW = expanding_element?.clientWidth ?? 0;
                    const totalW = window?.innerWidth ?? 0;

                    const targetScale = (totalW - currentW) / currentW;

                    gsap.to(expanding_element, {
                        transform: `scale(${1+targetScale})`,
                        transformOrigin: "top",
                        ease: "none",
                        scrollTrigger: {
                            trigger: section,
                            start: `top ${(section?.offsetTop ?? 0) + 80}`,
                            end: "top 112px",
                            scrub: 1
                        }
                    });
                }
                break;
            case "rotate-on-scroll":
                if (element) {
                    const section: HTMLDivElement | null = element as HTMLDivElement;
                    const rotating_elements: NodeListOf<HTMLDivElement> | null = element.querySelectorAll(`[data-scroll-element]`);

                    rotating_elements.forEach((el, index) => {
                        gsap.to(el, {
                            rotate: 180,
                            transformOrigin: "center",
                            ease: "none",
                            scrollTrigger: {
                                trigger: section,
                                start: "top bottom",
                                end: `bottom top`,
                                scrub: ((index + 1) * 4)
                            }
                        });
                    })
                }
                break;
            case "speed":
                if (element &&(document.body.clientWidth >= 1280 || (element as HTMLElement).dataset.enableOnMobile == "true")) {
                    const section: HTMLDivElement | null = element as HTMLDivElement;
                    const speeding_elements: NodeListOf<HTMLDivElement> | null = element.querySelectorAll(`[data-scroll-element]`);

                    speeding_elements.forEach((el) => {
                        gsap.to(el, {
                            y: el.dataset.scrollSpeed ?? -100,
                            transformOrigin: "center",
                            ease: "none",
                            scrollTrigger: {
                                trigger: section,
                                end: `bottom top`,
                                start: "top bottom",
                                scrub: 2
                            }
                        });
                    })
                }
                break;
            case "parallax":
                const section: HTMLDivElement | null = element as HTMLDivElement;
                const parallax_elements: NodeListOf<HTMLDivElement> | null = element.querySelectorAll(`[data-scroll-element]`);
                if (element && document.body.clientWidth >= 640) {
                    parallax_elements.forEach((el) => {
                        gsap.to(el, {
                            yPercent: -80,
                            transformOrigin: "center",
                            ease: "none",
                            scrollTrigger: {
                                trigger: section,
                                end: `bottom top`,
                                start: 0,
                                scrub: 2
                            }
                        });
                    })
                } else {
                    parallax_elements.forEach((el) => {
                        gsap.to(el, {
                            yPercent: -60,
                            transformOrigin: "center",
                            ease: "none",
                            scrollTrigger: {
                                trigger: section,
                                end: `bottom top`,
                                start: 0,
                                scrub: 2
                            }
                        });
                    })
                }
                break;
        }

    })
}