import React from 'react';

const Footer = () => {

    return (
        <footer>
            <nav>

            </nav>
        </footer>
    )

}

export default Footer;