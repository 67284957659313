import React, {ReactNode, useLayoutEffect} from "react";
import NavigationBar from "../navigation-bar/navigation-bar";
import Footer from "../footer/footer";
import "../../styles/global.css"
// @ts-ignore
import ReactLenis from "lenis/react";
import {initGsap} from "../../lib/gsap/gsap";

const Layout = ({ children }: { children: ReactNode }) => {

    useLayoutEffect(() => {
        initGsap()
    }, [])

    return (
        <>
            <ReactLenis root>
                {/*<NavigationBar/>*/}
                <main>
                    {children}
                </main>
                <Footer/>
            </ReactLenis>
        </>
    )

}

export default Layout;

